.background-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure the background layer is below the content */
  background-color: #fff9ef;
  opacity: 0.75;
}

.little-boob {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 150px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.line-curve {
  position: absolute;
  bottom: 20px;
  right: 0px;
  width: 200px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.line-curve-2 {
  position: absolute;
  top: 5px;
  left: 0;
  width: 250px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.palabok {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 20%;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.siomai {
  position: absolute;
  top: 40%;
  right: 50%;
  width: 200px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.palitaw {
  position: absolute;
  top: 10%;
  left: 15%;
  width: 300px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

/* Hide .palitaw on mobile devices */
@media (max-width: 768px) {
  .palitaw {
    display: none;
  }
}


.puto {
  position: absolute;
  bottom: 15%;
  left: 40%;
  width: 450px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.little-star {
  position: absolute;
  top: 80%; /* Adjust the position as needed */
  left: 30%; /* Adjust the position as needed */
  width: 80px; /* Adjust the size */
  height: auto;
  z-index: 1;
  opacity: 0.8;
}
