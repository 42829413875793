/* Import the necessary fonts */
@import url("https://fonts.googleapis.com/css2?family=LXGW+WenKai+TC&family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arial&display=swap");

/* Set the basic body styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #fff8e1; /* Creamy background color */
}

/* Ensure the app-container is positioned relative to contain the background layer */
.app-container {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden; /* Prevent overflow */
}

.right-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 2;
}

.left-column {
  flex: 1;
  padding: 20px;
  position: relative;
  z-index: 2;
}

/* Hide the left column on mobile view */
@media (max-width: 768px) {
  .left-column {
    display: none;
  }
}

/* The content wrapper is centered and has a max-width */
.content-wrapper {
  width: 100%;
  max-width: 600px; /* Decreased width of the content */
  padding: 20px;
  box-sizing: border-box;
}

/* Specific font for the logo header */
.lxgw-wenkai-tc-regular {
  font-family: "LXGW WenKai TC", cursive;
  font-weight: 400;
  font-style: normal;
  color: #f39c12; /* Apply the orange color to Ladykusina */
}

/* Specific font for the word "MENU" in the logo header */
.lilita-one-regular {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #f39c12; /* Apply the orange color to MENU */
  font-size: 3rem; /* Adjust this size to make MENU larger */
}

/* Menu section styling */
.menu-section {
  background-color: #fff8e1;
  border-radius: 10px;
  padding: 15px;
  border: 2px solid black;
  margin-bottom: 20px;
  width: 100%; /* Ensure it takes up the full width of the content-wrapper */
  box-sizing: border-box; /* Include padding and border in the width */
}

.menu-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px; /* Add space between columns */
}

.menu-item-name {
  font-family: "Arial", sans-serif;
  text-align: left;
}

.menu-item-price {
  font-family: "Arial", sans-serif;
  text-align: right;
  color: black;
  font-weight: bold;
  padding: 3px;
}

.menu-title {
  font-family: "Arial", sans-serif;
  color: #f39c12;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.text-black {
  color: black;
}


.footer {
  background-color: #f39c12; /* Orange background color */
  color: white;
  padding: 16px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between; /* Space between icons and text */
  align-items: center;
}

.footer-icons {
  display: flex;
  gap: 16px; /* Space between the icons */
}

.icon {
  font-size: 24px; /* Adjust size as needed */
  color: white;
  cursor: pointer;
  transition: transform 0.3s;
}

.icon:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

.footer-text {
  font-size: 1.25rem;
  font-weight: bold;
}